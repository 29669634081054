.toolbar-button-wrapper {
    position: relative;
    font-size: 24px;
    padding: 10px;
    // border: 1px solid $black;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;
    justify-content: center;
    
    
    .description{
        font-size: 12px;
        background-color: white;
        bottom: -100%;
        width: auto;
        position: absolute;
        overflow: hidden;
        transition: 0.2s;
        transform-origin: top;
        white-space: nowrap;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        height: 14px;
        padding: 10px 15px;
        transform: scaleY(0);
        border-radius: 10px;
    }
    
    &:hover{
        background-color: #e7e7e7;
        
        .description{
            transform: scaleY(1);
        }
    }

    &:not(:nth-last-of-type(1)){
        // margin-right: 10px;
    }

    .text {
        margin-left: 10px;
    }
}