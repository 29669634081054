@import '/src/styles/imports.scss';
$current: $mobile;
.contact-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .contact-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .contact-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .contact-title {
            width: vw(550, $root);
            line-height: vw(40, $current);
            background-color: rgba(142, 142, 142, 0.66);
            font-size: vw(20, $current);
            font-weight: bold;
            color: $white;
            letter-spacing: vw(20, $current);
            text-transform: uppercase;
            text-align: center;
            margin-bottom: vw(95, $root);
        }

        .contact-form-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .field {
                margin-bottom: vw(40, $root);
                width: vw(700, $root);
                color: $white;
                text-transform: uppercase;
                font-size: vw(14, $current);
                font-weight: bold;

                .float-label-background {
                    background-color: $black;
                }

                .float-input {
                    color: $white;
                    height: vw(40, $root);
                    border: 1px solid $white;
                    background-color: $black;
                }

                .float-textarea {
                    color: $white;
                    height: vw(250, $root);
                    border: 1px solid $white;
                    background-color: $black;
                }
            }

            .submit-button {
                margin-top: vw(30, $root);
                font-size: vw(16, $current);
                line-height: vw(30, $current);
                width: vw(356, $root);
                height: vw(61, $root);
                background-color: $white;
                color: $black;
                text-transform: uppercase;
                border-radius: 0;
                letter-spacing: vw(4, $current);
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .contact-wrapper {
        .contact-title {
            line-height: vw(40, $current);
            font-size: vw(20, $current);
            letter-spacing: vw(24, $current);
        }
        .contact-form-wrapper {
            .field {
                font-size: vw(20, $current);
            }
            .submit-button {
                font-size: vw(20, $current);
                line-height: vw(50, $current);
                letter-spacing: vw(4, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .contact-wrapper {
        .contact-title {
            line-height: vw(40, $current);
            font-size: vw(20, $current);
            letter-spacing: vw(24, $current);
        }
        .contact-form-wrapper {
            .field {
                font-size: vw(20, $current);
            }
            .submit-button {
                font-size: vw(20, $current);
                line-height: vw(50, $current);
                letter-spacing: vw(4, $current);
            }
        }
    }
}