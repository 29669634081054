@import '/src/styles/imports.scss';
$current: $mobile;
.portfolio-wrapper {
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .portfolio-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .portfolio-wrapper {
        height: vw(981, $root);
        display: flex;
        flex-direction: column;
        align-items: center;

        .portfolio-title {
            color: $white;
            font-size: vw(24, $current);
            line-height: vw(30, $current);
            letter-spacing: vw(20, $current);
            text-transform: uppercase;
            margin-bottom: vw(95, $root);
        }

        .portfolio-images-wrapper {
            width: 100vw;
            display: flex;
            flex-wrap: wrap;
            flex-basis: auto;

            .portfolio-image-wrapper {
                width: 20vw;
                height: vw(350, $current);

                .portfolio-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .portfolio-wrapper {
        .portfolio-title {
            font-size: vw(30, $current);
            line-height: vw(37, $current);
            letter-spacing: vw(31, $current);
        }

        .portfolio-images-wrapper {
            .portfolio-image-wrapper {
                height: vw(400, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .portfolio-wrapper {
        .portfolio-title {
            font-size: vw(30, $current);
            line-height: vw(37, $current);
            letter-spacing: vw(31, $current);
        }

        .portfolio-images-wrapper {
            .portfolio-image-wrapper {
                height: vw(490, $current);
            }
        }
    }
}