@import "../../styles/imports.scss";

.document-wrapper {
    margin: vw(50, $desktop_max) 0;
    width: 70vw;
    padding: vw(30, $desktop_max);
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
