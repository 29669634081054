@import '/src/styles/imports.scss';
$current: $mobile;
.screen {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .screen {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .screen {
        position: relative;
        margin-bottom: vw(400, $current);
        top: vw(300, $current);
        opacity: 0;
        transition: 500ms;

        &.animated {
            opacity: 1;
            top: 0;
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .screen {
        margin-bottom: vw(400, $current);
        top: vw(300, $current);
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .screen {
        margin-bottom: vw(400, $current);
        top: vw(300, $current);
    }
}