@import '/src/styles/imports.scss';
$current: $mobile;
.splash-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .splash-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .splash-wrapper {
        height: 100vh;
        width: 100vw;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .splash-background-wrapper {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            overflow: hidden;
            .splash-background {
                width: 100%;
                height: auto;
                object-fit: cover;
                margin-bottom: vw(-770, $root);
            }
        }

        .text {
            position: absolute;
            bottom: vw(250, $root);
            left: vw(400, $root);
            color: $white;
            text-transform: uppercase;
            width: vw(400, $root);
            text-align: center;

            .headline {
                margin-left: vw(-20, $root);
                font-size: vw(45, $current);
                font-weight: bold;
                line-height: vw(50, $current);
            }

            .subline {
                font-size: vw(24, $current);
                font-weight: lighter;
            }
        }

        .icon {
            color: $white;
            position: fixed;
            right: vw(100, $root);
            bottom: vw(60, $root);
            width: vw(50, $root);
            height: auto;
            
            svg {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .splash-wrapper {
        .text {
            .headline {
                font-size: vw(55, $current);
                line-height: vw(60, $current);
            }

            .subline {
                font-size: vw(30, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .splash-wrapper {
        .text {
            .headline {
                font-size: vw(70, $current);
                line-height: vw(80, $current);
            }

            .subline {
                font-size: vw(40, $current);
            }
        }
    }
}