@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import "./styles/imports.scss";

body {
    font-family: Nunito;
}

.App {
    background-color: $black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}


@media (min-width: $tablet_px) {
    .App {
        background-position-y: -40vw;
    }
}
@media (min-width: $desktop_px) {
    .App {
        background-position-y: -55vw;
    }
}
@media (min-width: $desktop_large_px) {
    
}
@media (min-width: $desktop_max_px) {
    
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

// ::-webkit-scrollbar {
//     // width: 7.5px;
//     display: none;
// }

// ::-webkit-scrollbar-track {
//     background-color: rgba(0, 0, 0, 0.3);
// }

// ::-webkit-scrollbar-thumb {
//     background: rgba(0, 0, 0, 0.7);
//     border-radius: 20px;
//     cursor: grab;
// }
