@import "../../../styles/imports.scss";

.overlay-wrapper {
    min-width: vw(300, $desktop_max);
    max-width: vw(600, $desktop_max);
    background-color: $white;
    border-radius: 10px;
    position: relative;
    top: -100px;
    cursor: default;
    z-index: 10001;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s;

    &.slide-down {
        top: 0;
    }

    .popup-header-wrapper {
        padding: vw(15, $desktop_max);
        max-height: vw(80, $desktop_max);

        border-radius: 10px 10px 0 0;
        background-color: $black;
        position: relative;
        z-index: 10;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-bottom: 4px solid $site-primary;

        .popup-header {
            color: $site-primary;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .popup-header-title-wrapper {
                display: flex;
                align-items: center;

                .popup-header-title {
                    margin-left: vw(10, $desktop_max);
                    text-transform: uppercase;
                    font-weight: bold;
                }

                .popup-header-icon {
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                }
            }

            .popup-header-close-icon {
                font-size: 24px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .main-wrapper {
        padding: vw(15, $desktop_max) vw(30, $desktop_max);
        padding-bottom: vw(30, $desktop_max);
    }
}
