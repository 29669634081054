@import '/src/styles/imports.scss';
$current: $mobile;
.about-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .about-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .about-wrapper {
        padding-inline: vw(300, $root);
        position: relative;
        display: flex;
        .about-left {
            position: relative;
            z-index: 1;
            width: vw(600, $root);
            height: vw(750, $root);

            .about-pic {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .about-right {
            position: relative;
            margin-inline-start: vw(120, $root);
            color: $white;
            width: vw(500, $root);


            .about-gray-block {
                right: vw(40, $root);
                top: vw(-30, $root);
                position: absolute;
                z-index: 0;
                width: vw(650, $root);
                height: vw(100, $root);
                background-color: rgba(142, 142, 142, 0.65);
            }

            .about-title {
                position: relative;
                font-size: vw(20, $current);
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: vw(15, $current);
                margin-bottom: vw(80, $root);
            }

            .about-name {
                font-size: vw(16, $current);
                text-transform: uppercase;
                font-weight: 400;
            }

            .about-paragraph {
                text-align: start;
                font-size: vw(14, $current);
                font-weight: 100;
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .about-wrapper {
        .about-right {
            .about-title {
                font-size: vw(24, $current);
                letter-spacing: vw(25, $current);
            }

            .about-name {
                font-size: vw(20, $current);
            }

            .about-paragraph {
                font-size: vw(16, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .about-wrapper {
        .about-right {
            .about-title {
                font-size: vw(30, $current);
                letter-spacing: vw(25, $current);
            }

            .about-name {
                font-size: vw(25, $current);
            }

            .about-paragraph {
                font-size: vw(18, $current);
            }
        }
    }
}