@import '/src/styles/imports.scss';
$current: $mobile;
.gallery-wrapper {
    .see-more-link {
        color: $site-primary;
        font-size: vw(10, $current);
        font-weight: bold;
        text-decoration: none;
        margin-top: vw(10, $current);
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .gallery-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .gallery-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: vw(1079, $root);
        height: vw(801, $root);

        .gallery-main-section {
            width: 100%;
            height: 100%;

            .selected-slide-picture-wrapper {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .selected-slide-picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .gallery-title-wrapper {
                position: absolute;
                bottom: 100%;
                right: 100%;
                z-index: 0;
                background-color: rgba(142, 142, 142, 0.65);
                width: vw(580, $root);
                height: vw(230, $root);
                transform: translateX(40%) translateY(85%);

                .gallery-texts-wrapper {
                    padding: vw(40, $root) vw(40, $root);
                    color: $white;
                    .gallery-title {
                        font-size: vw(24, $current);
                    }
                    .gallery-subline {
                        font-size: vw(11, $current);
                        letter-spacing: vw(7, $current);
                    }
                }
            }

            .gallery-carousel-wrapper {
                position: absolute;
                top: 0;
                bottom: 0;
                left: vw(1140, $root);
                margin: auto 0;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;

                .gallery-carousel {
                    width: vw(112, $root);
                    height: vw(730, $root);
        
                    .gallery-carousel-slide {
                        cursor: pointer;
                        width: vw(112, $root);
                        height: vw(163, $root);
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        .gallery-slide-img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .arrow-wrapper {
                    border: none;
                    background: none;
                    margin: vw(15, $root) 0;
                    cursor: pointer;

                    &.flip {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        
        .see-more-link {
            position: absolute;
            top: 100%;
            font-size: vw(10, $current);
            margin-top: vw(10, $root);
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .gallery-wrapper {
        .gallery-main-section {
            .gallery-title-wrapper {
                .gallery-texts-wrapper {
                    .gallery-title {
                        font-size: vw(35, $current);
                    }
                    .gallery-subline {
                        font-size: vw(16, $current);
                        letter-spacing: vw(7, $current);
                    }
                }
            }
        }
        .see-more-link {
            font-size: vw(12, $current);
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .gallery-wrapper {
        .gallery-main-section {
            .gallery-title-wrapper {
                .gallery-texts-wrapper {
                    .gallery-title {
                        font-size: vw(35, $current);
                    }
                    .gallery-subline {
                        font-size: vw(16, $current);
                        letter-spacing: vw(7, $current);
                    }
                }
            }
        }
        .see-more-link {
            font-size: vw(12, $current);
        }
    }
}