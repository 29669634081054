@import "../../styles/imports.scss";

$current: $mobile;

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: vw(50, $current);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    z-index: 99;

    & > * {
        cursor: pointer;
    }

    
    .header-content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;

        .logo-wrapper {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .link-styling-remove {
                text-decoration: none;
                color: $white;
            }
            .logo-image {
                width: auto;
                height: 75%;
            }

            .logo-text {
                text-transform: uppercase;

                a {
                    text-decoration: none;
                }

                .main-line {
                    font-weight: bold;
                    font-size: vw(12, $current);
                    letter-spacing: vw(5, $current);
                }

                .sub-line {
                    text-align: center;
                    font-size: vw(9, $current);
                    letter-spacing: vw(5, $current);
                }
            }
        }

        .menu-icon {
            cursor: default;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            .icon {
                padding-left: vw(20, $current);
                font-size: vw(24, $current);
                transition: 0.2s;
                &:not(:nth-last-of-type(3n+1)){
                    display: none;
                }
            }
        }

        .mobilefix{
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            padding-right: vw(20, $current);
            font-size: vw(24, $current);
            visibility: hidden;
        }
    }
}

@media (min-width: $tablet_px) {
    $current: $tablet;

    .header-wrapper {
        height: vw(50, $current);
        .header-content-wrapper {
            .logo-wrapper {
                .logo-text {
                    .main-line {
                        font-size: vw(12, $current);
                        letter-spacing: vw(5, $current);
                    }
                    .sub-line {
                        font-size: vw(9, $current);
                        letter-spacing: vw(5, $current);
                    }
                }
            }
            .menu-icon {
                .icon {
                    padding-left: vw(20, $current);
                    font-size: vw(24, $current);
                }
            }
            .mobilefix{
                padding-right: vw(20, $current);
                font-size: vw(24, $current);
            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;

    .header-wrapper{
        height: vw(40, $current);
        transition: 0.5s;
        transition-timing-function: ease-in-out;
        padding: vw(20, $current) 0;

        & > * {
            cursor: pointer;
        }

        &.scrolled {
            height: vw(20, $current);
        }


        .header-content-wrapper {
            width: 100%;
            flex-direction: row;
            padding: 0 vw(100, $current);

            .logo-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: vw(200, $current);
                margin-right: auto;

                .logo-img-wrapper.hide,
                .logo-text-wrapper.hide {
                    pointer-events: none;
                    opacity: 0;
                    max-height: 0;
                    transition: max-height 0.3s, opacity 0.3s 0.3s;
                }

                .logo-img-wrapper {
                    pointer-events: all;
                    position: absolute;
                    opacity: 1;
                    overflow: hidden;
                    max-height: vw(40, $current);
                    height: vw(40, $current);
                    width: 100%;
                    transition: max-height 0.3s 0.3s, opacity 0.3s;
                    transform-origin: bottom;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .logo-text-wrapper {
                    pointer-events: all;
                    position: absolute;
                    opacity: 1;
                    overflow: hidden;
                    width: 100%;
                    max-height: vw(40, $current);
                    transition: max-height 0.3s 0.3s, opacity 0.3s;
                    transform-origin: top;

                    .logo-text {
                        margin-left: vw(12, $current);
                        text-align: center;
        
                        .main-line {
                            font-size: vw(14, $current);
                            letter-spacing: vw(5, $current);
                        }
        
                        .sub-line {
                            font-size: vw(11, $current);
                            letter-spacing: vw(5, $current);
                        }
                    }
                }
            }
            .menu-icon {
        
                .icon {
                    cursor: pointer;
                    padding: vw(10, $current);
                    font-size: vw(26, $current);
                    &:not(:nth-last-of-type(3n+1)){
                        display: unset;
                    }
                    opacity: 1;
                    pointer-events: all;
                    transform: 0.3s;

                    &.hide {
                        pointer-events: none;
                        opacity: 0;
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
        
    .header-wrapper{
        height: vw(40, $current);
        padding: vw(20, $current) 0;

        &.scrolled {
            height: vw(20, $current);
        }

        .header-content-wrapper {
            padding: 0 vw(200, $current);
            .logo-wrapper {
                max-width: vw(400, $current);
    
                .logo-img-wrapper {
                    height: vw(40, $current);

                    img {
                        height: 100%;
                    }
                }
    
                .logo-text-wrapper {
    
                    .logo-text {
                        margin-left: vw(20, $current);
        
                        .main-line {
                            font-size: vw(16, $current);
                            letter-spacing: vw(10, $current);
                        }
        
                        .sub-line {
                            font-size: vw(12, $current);
                            letter-spacing: vw(5, $current);
                        }
                    }
                }
            }
            .menu-icon {
        
                .icon {
                    padding: vw(10, $current);
                    font-size: vw(26, $current);
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;

    .header-wrapper{
        height: vw(40, $current);
        padding: vw(20, $current) 0;

        &.scrolled {
            height: vw(20, $current);
        }
        
        .header-content-wrapper {
            padding: 0 vvw(200, $current);
            .logo-wrapper {
                max-width: vw(400, $current);
    
                .logo-img-wrapper {
                    height: vw(40, $current);
                }
    
                .logo-text-wrapper {
    
                    .logo-text {
                        margin-left: vw(20, $current);
        
                        .main-line {
                            font-size: vw(16, $current);
                            letter-spacing: vw(10, $current);
                        }
        
                        .sub-line {
                            font-size: vw(12, $current);
                            letter-spacing: vw(5, $current);
                        }
                    }
                }
            }
            .menu-icon {
        
                .icon {
                    padding: vw(10, $current);
                    font-size: vw(26, $current);
                }
            }
        }
    }
}


@keyframes fadeAndHide {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        display: none;
    }
}