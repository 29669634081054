@import '/src/styles/imports.scss';
$current: $mobile;
.bubble-link-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .bubble-link-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .bubble-link-wrapper {
        width: 100%;
        padding: vw(10, $current) 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $black;
        font-size: vw(14, $current);
        position: relative;
        overflow: hidden;
        transition: 0.2s;
        cursor: pointer;
    
        .title {
            z-index: 100;
            transition: 0.2s;
            transition-delay: 0.6s;
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .bubble-link-wrapper {
        padding: vw(10, $current) 0;
        font-size: vw(14, $current);
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .bubble-link-wrapper {
        padding: vw(10, $current) 0;
        font-size: vw(14, $current);
    }
}