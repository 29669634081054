@import "../../styles/imports.scss";

.button-wrapper {
    // min-width: 90px;
    width: 100%;
    padding: 5px 10px;
    height: 1.8rem;
    background-color: $site-primary;
    color: $white;
    border-radius: 5px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover:not(.disabled) {
        background: $site-primary-hover;
    }

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }

    .button-text {
        font-size: 14px;
    }

    .button-icon {
        height: 16px;
        width: auto;
        margin-left: 5px;
    }
}
