@import "../../../styles/imports.scss";
$current: $mobile;
.footer-wrapper {
    backdrop-filter: blur(10px);
    background-color: $white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $black;
    // position: sticky;
    // bottom: 0;
    padding: vw(20, $current) 0;

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .logo-image {
            width: vw(50, $current);
        }

        .logo-text-wrapper {
            margin-top: vw(5, $current);
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            left: vw(2, $current);

            .main-line {
                position: relative;
                left: vw(2, $current);
                font-weight: bold;
                font-size: vw(14, $current);
                letter-spacing: vw(5, $current);
            }

            .sub-line {
                font-weight: bold;
                letter-spacing: vw(3, $current);
                font-size: vw(12, $current);
            }
        }
    }

    .socialmedia-wrapper {
        display: flex;

        .socialmedia-item {
            cursor: pointer;
            font-size: vw(20, $current);
            margin: vw(5, $current) vw(10, $current);
            color: $black;
        }
    }

    .login-section {
        cursor: pointer;
        font-size: vw(9, $current);
        color: $black;
        text-transform: uppercase;
        text-decoration: none;
    }

    .copyrights {
        margin: vw(10, $current) 0;
        // margin-bottom: 0;
        font-size: vw(8, $current);
        width: 50%;
        text-align: center;
    }

    .terms-and-privacy {
        margin-bottom: vw(10, $current);
        display: flex;
        align-items: baseline;

        .seperator {
            margin-inline: vw(5, $current);
            font-size: vw(9, $current);
        }
    }
}


@media (min-width: $tablet_px) {
    $current: $tablet;
    .footer-wrapper {
        padding: vw(20, $current) 0;
    
        .logo-wrapper {
    
            .logo-image {
                width: vw(50, $current);
            }
    
            .logo-text-wrapper {
                margin-top: vw(5, $current);
                left: vw(2, $current);
    
                .main-line {
                    left: vw(2, $current);
                    font-size: vw(14, $current);
                    letter-spacing: vw(5, $current);
                }
    
                .sub-line {
                    letter-spacing: vw(3, $current);
                    font-size: vw(12, $current);
                }
            }
        }
    
        .socialmedia-wrapper {
            .socialmedia-item {
                font-size: vw(20, $current);
                margin: vw(5, $current) vw(10, $current);
            }
        }
    
        .login-section {
            font-size: vw(9, $current);
        }
    
        .copyrights {
            margin: vw(10, $current) 0;
            font-size: vw(8, $current);
        }
    
        .terms-and-privacy {
            margin-bottom: vw(10, $current);
    
            .seperator {
                margin-inline: vw(5, $current);
                font-size: vw(9, $current);
            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $root: $desktop_max;
    .footer-wrapper {
        min-height: vw(200, $root);
        padding: vw(30, $root) 0;
    
        .logo-wrapper {
            .logo-image {
                width: vw(70, $root);
            }
    
            .logo-text-wrapper {
                margin-top: vw(5, $root);
                left: vw(2, $root);
    
                .main-line {
                    left: vw(5, $root);
                    font-size: vw(18, $current);
                    letter-spacing: vw(10, $current);
                }
    
                .sub-line {
                    letter-spacing: vw(4, $current);
                    font-size: vw(14, $current);
                }
            }
        }
    
        .socialmedia-wrapper {
    
            .socialmedia-item {
                font-size: vw(20, $current);
                margin: vw(5, $root) vw(10, $root);
            }
        }
    
        .login-section {
            font-size: vw(8, $current);
        }
    
        .copyrights {
            margin: vw(10, $root) 0;
            // margin-bottom: 0;
            font-size: vw(10, $current);
        }
    
        .terms-and-privacy {
            margin-bottom: vw(10, $root);
    
            .seperator {
                margin-inline: vw(5, $root);
                font-size: vw(10, $current);
            }
        }
    }  
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .footer-wrapper {
        .logo-wrapper {
            .logo-text-wrapper {
    
                .main-line {
                    font-size: vw(18, $current);
                    letter-spacing: vw(10, $current);
                }
    
                .sub-line {
                    letter-spacing: vw(4, $current);
                    font-size: vw(14, $current);
                }
            }
        }
    
        .socialmedia-wrapper {
            .socialmedia-item {
                font-size: vw(20, $current);
            }
        }
    
        .login-section {
            font-size: vw(8, $current);
        }
    
        .copyrights {
            font-size: vw(10, $current);
        }
    
        .terms-and-privacy {
            .seperator {
                font-size: vw(10, $current);
            }
        }
    }
    
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .footer-wrapper {
        .logo-wrapper {    
            .logo-text-wrapper {
                .main-line {
                    font-size: vw(18, $current);
                    letter-spacing: vw(10, $current);
                }
    
                .sub-line {
                    letter-spacing: vw(4, $current);
                    font-size: vw(14, $current);
                }
            }
        }
    
        .socialmedia-wrapper {
            .socialmedia-item {
                font-size: vw(20, $current);
            }
        }
    
        .login-section {
            font-size: vw(8, $current);
        }
    
        .copyrights {
            font-size: vw(10, $current);
        }
    
        .terms-and-privacy {
            .seperator {
                font-size: vw(10, $current);
            }
        }
    }
}