@import "../../../../styles/imports.scss";

// .tags-manager-wrapper {
//     width: 100%;
//     display: flex;
//     flex-direction: column;

//     .tags-manager-buttons-wrapper {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         margin-bottom: vw(10, $desktop_max);

//         .left-side {
//             display: flex;
//             align-items: center;
//             overflow: auto;
//             $white-space: nowrap;

//             & > * {
//                 margin-right: vw(10, $desktop_max);
//             }
//         }
//     }
//     .main-area-wrapper {
//         display: flex;
//         height: 100%;
//         width: 100%;
//         justify-content: center;
//     }
// }

@media (min-width: $tablet_px) {
}
@media (min-width: $desktop_px) {
    .tags-manager-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        .tags-manager-buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: vw(10, $desktop_max);
    
            .left-side {
                display: flex;
                align-items: center;
                overflow: auto;
                $white-space: nowrap;
    
                & > * {
                    margin-right: vw(10, $desktop_max);
                }
            }
        }
        .main-area-wrapper {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
        }
    }
}
@media (min-width: $desktop_large_px) {
}
@media (min-width: $desktop_max_px) {
}
