$site-secondary: #4f1804;
$site-primary: #f6bd2b;
$site-primary-hover: #ea992e;
$white: white;
$black: black;

$site-secondary-opacity: rgba(228, 76, 52, 0.11);
$site-white-opacity: rgba(255, 255, 255, 0.4);

/* SIZES */
$mobile: 320;
$tablet: 768;
$desktop: 1200;
$desktop_large: 1500;
$desktop_max: 1920;

/* SIZES PX */
$mobile_px: 320px;
$tablet_px: 768px;
$desktop_px: 1200px;
$desktop_large_px: 1500px;
$desktop_max_px: 1920px;