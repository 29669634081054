@import '/src/styles/imports.scss';
$current: $mobile;

$radius: vw(10, $current);

$menu-items: 4;
$menu-items-loop-offset: $menu-items - 1;
$height: (100 / $menu-items) * 1%;

.bubble-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .bubble-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    $radius: vw(10, $current);
    
    .bubble-wrapper {
        position: absolute;
        z-index: 99;
        top: vw(36.5, $current);
        right: vw(17, $current);
        height: 0;
        transition: 0.2s;

        .arrow-up {
            width: 0;
            height: 0;
            border-left: vw(150, $current) solid transparent;
            border-right: 0 solid transparent;

            border-bottom: vw(7.5, $current) solid $white;
            margin-left: auto;
        }

        .main {
            box-shadow: vw(-5, $current) vw(5, $current) vw(5, $current) rgba(0, 0, 0, 0.2);
            border-radius: $radius 0 $radius $radius;
            padding: vw(10, $current) vw(20, $current);
            background-color: $white;
            min-width: vw(100, $current);
            display: flex;
            flex-direction: column;
            position: relative;

            .indicator {
                position: absolute;
                width: 100%;
                left: 0;
                background-color: $site-primary;
                transition: 400ms ease-in-out;
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    $radius: vw(10, $current);
    
    .bubble-wrapper {
        top: vw(36.5, $current);
        right: vw(17, $current);

        .arrow-up {
            border-left: vw(150, $current) solid transparent;
            border-bottom: vw(7.5, $current) solid $white;
        }

        .main {
            box-shadow: vw(-5, $current) vw(5, $current) vw(5, $current) rgba(0, 0, 0, 0.2);
            border-radius: $radius 0 $radius $radius;
            padding: vw(10, $current) vw(20, $current);
            min-width: vw(100, $current);
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    $radius: vw(10, $current);
    
    .bubble-wrapper {
        top: vw(36.5, $current);
        right: vw(17, $current);

        .arrow-up {
            border-left: vw(150, $current) solid transparent;
            border-bottom: vw(7.5, $current) solid $white;
        }

        .main {
            box-shadow: vw(-5, $current) vw(5, $current) vw(5, $current) rgba(0, 0, 0, 0.2);
            border-radius: $radius 0 $radius $radius;
            padding: vw(10, $current) vw(20, $current);
            min-width: vw(100, $current);
        }
    }
}